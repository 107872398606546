import './style.scss';

import reinhard from './../../img/reinhard.jpg';
import React from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Button,
  Typography,
  message,
  Checkbox,
  Col,
} from 'antd';

import Fade from 'react-reveal/Fade';
import startPic from '../../img/LawyerService/StartPic.png';
import writeLogo from '../../img/LawyerService/Write_Anfrage.png';
import Zoom from 'react-medium-image-zoom';
import bsp1 from '../../img/LawyerService/bsp1.png';
import bsp2 from '../../img/LawyerService/bsp2.png';
import cornerLeft from '../../img/corner_left.png';
import cornerRight from '../../img/corner_right.png';
import mailLogo from '../../img/LawyerService/Email.png';
import logo from '../../img/logo_stift.jpeg';
import comic from '../../img/LawyerService/lawyerComic.png';
import budget from '../../img/LawyerService/Budget.png';
import errichEtlin from '../../img/erichettlin.png';
import zanetti from '../../img/zanetti.png';
import schweizEid from '../../img/schweizereid.png';
import { useHistory } from 'react-router';
const { Title } = Typography;

export default function LawyerService_New() {
  const hist = useHistory();

  return (
    <>
      <div className='lawyerMain'>
        <div className='lawyerContent' style={{ position: 'relative' }}>
          <img
            src={cornerLeft}
            alt='corner'
            style={{
              width: '17vw',
              position: 'fixed',
              top: 90,
              left: 0,
            }}></img>
          <img
            src={cornerRight}
            alt='corner'
            style={{
              width: '17vw',
              zIndex: 1,
              position: 'fixed',
              bottom: 0,
              right: 0,
            }}></img>
          <Row
            gutter={[50, 0]}
            type='flex'
            style={{ alignItems: 'center', marginTop: '150px' }}
            justify='center'>
            <Col xs={24} md={10}>
              <Title className='lawyerTitle' style={{ marginTop: 'auto' }}>
                Ein solides <br />
                Schreiben verfassen. <br />
                Wir unterstützen <br />
                Sie dabei.
              </Title>
              <p>
                Sie kennen das. Ein Schreiben liegt Ihnen auf dem Magen: Ärger
                mit Kunden, Geschäftspartnern oder Behörden. Nach mühsamem Hin
                und Her am Telefon und im Netz ist die Zeit reif, mit einem
                kurzen und prägnanten Schreiben den Fall in Richtung einer guten
                Lösung zu führen.
              </p>
              <Button
                size='large'
                style={{ marginTop: 10 }}
                type='primary'
                onClick={() => navToForm(hist)}>
                Jetzt loslegen
              </Button>
            </Col>
            <Col xs={24} md={10}>
              <img src={startPic} alt='start pic' width='120%'></img>
            </Col>
          </Row>
          <Fade bottom>
            {/*     needs some sort of spacer here      */}
            <Row justify='center'>
              <Col style={{ textAlign: 'center' }}>
                <img
                  src={logo}
                  alt='logo'
                  width='200em'
                  style={{ marginBottom: '20px' }}></img>
                <Title level={2}>
                  Ein nüchtern, klar und sachlich formuliertes <br />
                  Schreiben genügt oft, um Zeit und Nerven raubende <br />
                  Rechtsstreitigkeiten zu verhindern.
                </Title>
              </Col>
            </Row>
            {/*     needs some sort of spacer here      */}
            <Row
              type='flex'
              style={{ alignItems: 'center' }}
              justify='center'
              gutter={[100, 0]}>
              <Col xs={24} md={9}>
                <Title
                  className='lawyerTitle'
                  style={{ marginTop: 'auto' }}
                  level={4}>
                  Übergeben Sie uns den Aufwand um sich ganz auf Ihr Geschäft
                  fokussieren zu können
                </Title>
                <p>
                  Geben Sie uns Ihr Anliegen ganz einfach per Anmeldeformular
                  oder Telefon bekannt. Solidares offeriert aufgrund dessen dann
                  die Verfassung eines individuell formulierten Schreibens,
                  welches Sie nur noch ausdrucken, unterschreiben und versenden
                  müssen.
                </p>
              </Col>
              <Col xs={24} md={10} style={{ textAlign: 'center' }}>
                <Title level={4}>Beispiele aus der Praxis</Title>
                <Zoom wrapStyle={{ width: '50%' }}>
                  <img src={bsp1} alt='start pic' width='100%'></img>
                </Zoom>
                <Zoom wrapStyle={{ width: '50%' }}>
                  <img src={bsp2} alt='start pic' width='100%'></img>
                </Zoom>
              </Col>
            </Row>
            {/*     needs some sort of spacer here      */}
            <Row
              gutter={[100, 0]}
              type='flex'
              style={{ alignItems: 'center' }}
              justify='center'>
              <Col xs={24} md={10}>
                <img src={comic} alt='start pic' width='90%'></img>
              </Col>
              <Col xs={24} md={9}>
                <Title level={2}>Problem gelöst</Title>
                <p>
                  Im Idealfall ist die Geschichte damit erledigt, doch selbst
                  wenn es weitergeht, bleibt Ihr Schreiben ein nützliches
                  Beweismittel für den weiteren Verlauf des Falles. Wir sind uns
                  sicher, dass diese Dienstleistung eine effiziente und
                  kostengünstige Alternative zur Lösung eines Konfliktes
                  darstellt, fernab von aufwändigen und kostspieligen
                  juristischen Methoden.
                </p>
              </Col>
            </Row>

            <Row
              type='flex'
              style={{ alignItems: 'center' }}
              justify='center'
              gutter={[100, 0]}>
              <Col xs={24} md={9}>
                <Title level={4}>Was kostet Sie das?</Title>
                <p>
                  Wir bieten den Schreibservice zu einem Tarif an, auf den wir
                  uns ganz einfach vorgängig einigen. Es ist ein freiwilliger
                  Beitrag Ihrerseits an den Verein und sie bestimmen wieviel Sie
                  bereit sind zu geben. Das Geld geht in die Vereinskasse und
                  wird dann für die Lancierung von weiteren solidarischen
                  Initiativen verwendet. Für Solidares heisst es an erster
                  Stelle, erste Unterstützung zu leisten. Gestartet wird mit
                  einem Telephongespräch von ca. 1/2 Stunde (wenns länger geht,
                  gehts länger), in welchem der beabsichtigte Inhalt festgelegt
                  wird.
                </p>
              </Col>
              <Col xs={24} md={7} style={{ textAlign: 'center' }}>
                <img
                  src={budget}
                  alt='budget'
                  style={{ boxShadow: '7px 7px 15px 2px' }}></img>
              </Col>
            </Row>
            <div className='lawyer-box'>
              <Row
                type='flex'
                style={{
                  marginBottom: 0,
                }}
                gutter={[100, 0]}
                justify='center'>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Title level={2}>Die Anfrage ist einfach</Title>
                </Col>
                <RequestCol
                  icon={logo}
                  headline='Als Unternehmen 
            registrieren'
                  text='Registrieren Sie Ihren Betrieb ganz einfach und kostenlos auf der Solidares Webseite.'></RequestCol>
                <RequestCol
                  icon={writeLogo}
                  headline='Anliegen bekannt 
            geben'
                  text='Geben Sie uns Ihr Anliegen ganz einfach per Anmeldeformular oder Telefon bekannt.'></RequestCol>
                <RequestCol
                  icon={mailLogo}
                  headline='Individuelles 
            Schreiben erhalten'
                  text='Sie erhalten ein individuell formuliertes Schreiben, welches Sie nur noch ausdrucken, unterschreiben und versenden müssen.'></RequestCol>
              </Row>
              <Row
                justify='center'
                style={{ marginTop: '20px', paddingBottom: '20px' }}>
                <Button
                  size='large'
                  type='primary'
                  onClick={() => navToForm(hist)}>
                  Jetzt loslegen
                </Button>
              </Row>
            </div>
            <Row
              type='flex'
              style={{ alignItems: 'center', marginTop: -100 }}
              justify='center'
              gutter={[10, 0]}>
              <Col xs={24} md={7}>
                <img
                  alt='Reinhard-deroo'
                  src={reinhard}
                  style={{
                    borderRadius: '80%',
                    marginRight: '20px',
                  }}></img>
              </Col>
              <Col xs={24} md={10}>
                <p
                  style={{
                    paddingTop: '4%',
                  }}>
                  Dr.iur. Matthias Reinhard-DeRoo, Co-Produzent von Solidares,
                  ist Jurist und Verfasser anspruchsvoller Texte. Seine
                  Erfahrungen in der Privatwirtschaft haben ihm gezeigt, dass
                  ein nüchtern, klar und sachlich formuliertes Schreiben oft
                  genügt, um Zeit und Nerven raubende Rechtsstreitigkeiten zu
                  verhindern.
                </p>
              </Col>
            </Row>
            <Row
              align='middle'
              style={{
                textAlign: 'center',
                marginBottom: 70,
                marginTop: '-100px',
              }}
              justify='center'>
              <Col xs={22} md={19} style={{ textAlign: 'left' }}>
                <Title level={3}>Finden uns gut:</Title>
                <img
                  alt={'errich'}
                  src={errichEtlin}
                  width='35%'
                  style={{ float: 'left' }}
                />
                <img
                  alt={'zanetti'}
                  src={zanetti}
                  width='35%'
                  style={{ float: 'left' }}
                />
                <a href='https://www.kmu.admin.ch/kmu/de/home/praktisches-wissen/finanzielles/finanzierung/beteiligungsfinanzierung/crowdfunding/unterstuetzungsmassnahmen-seitens-des-privaten-sektors.html'>
                  {' '}
                  <img
                    alt={'schweiz'}
                    src={schweizEid}
                    width='30%'
                    style={{
                      float: 'right',
                      marginTop: '15px',
                    }}></img>
                </a>
              </Col>
            </Row>
          </Fade>
        </div>
      </div>
      <Row
        style={{
          width: '100%',
          height: '7em',
          backgroundColor: '#a6a6a6',
          marginTop: 50,
          zIndex: 2,
          paddingTop: '10px',
          color: 'whitesmoke',
          position: 'absolut',
          bottom: 0,
          textAlign: 'center',
        }}
        justify='space-between'>
        <Col span={8}>
          BENÖTIGEN SIE HILFE?
          <br />
          Rufen Sie an:
          <br />
          0784330835
        </Col>
        <Col span={8}>
          Kontaktieren Sie uns mit einer Nachricht <br />
          <a href={'mailto:kontakt@solidares.ch'}>kontakt@solidares.ch</a>
        </Col>
        <Col span={8}>© 2020 – Verein Solidares</Col>
      </Row>
    </>
  );
}

function navToForm(hist) {
  hist.push('/anmeldung');
}

function RequestCol(props) {
  return (
    <Col xs={24} md={7} style={{ textAlign: 'center' }}>
      <img
        src={props.icon}
        alt='icon'
        style={{ height: '9em', marginBottom: '20px' }}></img>
      <p style={{}}>
        <b>{props.headline}</b>
      </p>
      <p>{props.text}</p>
    </Col>
  );
}
