import './App.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Button, Col, Popover, Row, Typography } from 'antd';
import AreaCards from './Components/AreaCards';
import PlaceFilterDisplay from './Components/PlaceFilterDisplay';
import ShareOptions from './Components/ShareOptions';
import img from './img/logo.jpeg';
import moneyCabLogo from './img/moneyCabLogo.png';
import cartoon from './img/cartoonCook.png';
import SubmitPlace from './Components/Forms/SubmitPlace';
import RegisterPlace from './Components/Forms/RegisterPlace';
import RegisterPlaceSuccessful from './Components/RegistrationSuccessful';
import BuyGiftCard from './Components/BuyGiftCard';
import IndustryDropdown from './Components/IndustryDropdown';
import AGB from './Components/AGB';
import LawyerService from './Components/LawyerService.js';
import LawyerService_New from './Components/LawyerService/Index';
import LawyerSubmit from './Components/Forms/LawyerSubmit';
import LandingPage from './Components/LandingPage_New';
import LawyerForm from './Components/LawyerService/Form/Master';
import Contact from './Components/Contact';
import About from './Components/About';
import ScrollToTop from 'react-router-scroll-top';
import { HashRouter, Link, Route, Switch, useHistory } from 'react-router-dom';
import errichEtlin from './img/erichettlin.png';
import zanetti from './img/zanetti.png';
import schweizEid from './img/schweizereid.png';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.baseURL = 'https://api.solidares.ch';
//axios.defaults.baseURL = 'http://localhost:8000';

const { Title } = Typography;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestedPlaces: null,
    };

    this.selfRef = React.createRef();
  }

  componentDidMount() {}

  render() {
    return (
      <HashRouter>
        <Header showFaq={this.showFAQModal} />
        <ScrollToTop />
        <div>
          <div style={{ marginTop: '0px' }}>
            <Row className='hero-row'>
              <ScrollToTop />
              <Switch>
                <Route path='/anmeldung'>
                  <LawyerForm></LawyerForm>
                </Route>
                <Route path='/schreibService'>
                  <LawyerService_New></LawyerService_New>
                </Route>
                <Route path='/schreibService'>
                  <LawyerService></LawyerService>
                </Route>
                <Route path='/lawyerSubmit'>
                  <LawyerSubmit></LawyerSubmit>
                </Route>
                <Route path='/submitPlace'>
                  <SubmitPlace></SubmitPlace>
                </Route>
                <Route path='/registerPlace'>
                  <RegisterPlace></RegisterPlace>
                </Route>
                <Route path='/registerPlaceSuccessful'>
                  <RegisterPlaceSuccessful></RegisterPlaceSuccessful>
                </Route>
                <Route path='/buyGiftCard/:id' component={BuyGiftCard}></Route>
                <Route path='/agb'>
                  <AGB></AGB>
                </Route>
                <Route path='/contact'>
                  <Contact></Contact>
                </Route>
                <Route path='/about'>
                  <About></About>
                </Route>
                <Route path='/giftCardService'>
                  <Main></Main>
                </Route>
                <Route path='/'>
                  <LandingPage></LandingPage>
                </Route>
              </Switch>
            </Row>
          </div>
        </div>
      </HashRouter>
    );
  }
}

function Main(props) {
  const [Areas, setAreas] = useState([]);
  const [SuggestedPlaces, setSuggestedPlaces] = useState([]);
  const [SelectedIndustry, setSelectedIndustry] = useState('all');
  const [SelectedArea, setSelectedArea] = useState('AG');
  const [SelectedPlace, setSelectedPlace] = useState();
  const history = useHistory();

  useEffect(() => {
    axios.get('/api/places/areas', {}).then(
      (result) => {
        setAreas(result.data.Areas);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  const onAutoSuggestPlaceSelected = (place) => {
    setSelectedPlace(place);

    setSelectedArea(place.area);
    setSelectedIndustry(place.industry);

    loadSuggestedPlaces(place.area, place.industry);
  };

  const updateIndustry = (industry) => {
    if (industry) {
      setSelectedIndustry(industry);
    }

    loadSuggestedPlaces(undefined, industry);
  };

  const updateArea = (area) => {
    if (area) {
      setSelectedArea(area);
    }

    loadSuggestedPlaces(area, undefined);
  };

  const selectPlace = (place) => {
    setSelectedPlace(place);
  };

  const loadSuggestedPlaces = (area, industry) => {
    let areaKey = area ? area : SelectedArea;
    let industryKey = industry ? industry : SelectedIndustry;

    console.log(industryKey);

    axios
      .get('/api/places/place_suggestions', {
        params: {
          area_id: areaKey,
          industry_id: industryKey,
        },
      })
      .then((result) => {
        setSuggestedPlaces(result.data.Places);
      })
      .finally();
  };

  const pressCard = (props) => {
    return (
      <div className='pressCard'>
        <img src={props.image} width='130px' alt='press logo'></img> <br />
        <div style={{ fontSize: 18, marginBottom: 5 }}>{props.title}</div>
        {props.type}
        <br />
        {props.date}
        <br />
        <a href={props.link}>Hier lesen</a>
      </div>
    );
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '100px auto' }}>
      <div className='header-row'>
        <Row justify='center' align='middle'>
          <Col xs={24} md={16}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '4em' }}>
              Gutsein-Gutschein.
            </Title>
            <div
              className='header-underheader'
              style={{
                textAlign: 'center',
              }}>
              Hier kannst Du bis zu <b className='text-orange'>20%</b> günstiger
              einkaufen und direkt im jeweiligen Geschäft einlösen. Betrieben
              bieten wir eine solide Lösung, die auf neuen Wegen Liquidität
              verschafft und die Netzpräsenz erhöht. Kurzum: was gute Betriebe
              suchen.
              <p style={{ fontSize: '2em', marginBottom: 0 }}>
                Gut. Solid. Solidares.
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 18, push: 0 }}
            sm={{ span: 10, push: 0 }}
            md={{ span: 6, push: 0 }}>
            <img
              src={cartoon}
              style={{ width: '110%', margin: '0 auto' }}
              alt='Cartoon'></img>
          </Col>
        </Row>
      </div>
      <div className='header-row'>
        <Row justify='center' align='middle'>
          <Col xs={24} style={{ padding: 20 }}>
            <Title level={4}>Aktuell:</Title>
            <p>
              <b>Jetzt gehts erst richtig los!</b> <br />
              Der Alltag hat uns wieder! Solidarität will nun gelebt und nicht
              bloss bekundet werden. Wir sahen die Corona Krise von Anfang an
              als eine Gelegenheit, ein Unternehmen neu auszurichten und solide
              Lösungen zu finden, die neue Einnahmequellen erschliessen und die
              Netzpräsenz permanent verstärken. Hierzu bieten wir unser Know-How
              und unsere Erfahrung. Wir wollen dazu beitragen, dass Ihr Geschäft
              Ihnen und Ihren Kunden wieder Freude bereitet. <br />
              <b> Wir setzen um worüber gesprochen wird. Über Solidarität! </b>
              <span style={{ textAlign: 'right', float: 'right' }}>
                <Button
                  shape='round'
                  onClick={() => {
                    history.push('/registerPlace');
                  }}
                  className='header-button'
                  style={{ marginRight: '0.4em', marginBottom: '0.2em' }}>
                  Melde Dich mit Deinem Unternehmen hier an!
                </Button>
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <Col
        sm={{ span: 20, offset: 2 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 20, offset: 2 }}>
        <div className='main-results'>
          <Row justify='start' style={{ padding: 20 }} align='middle'>
            <Col span={24}>
              <IndustryDropdown
                selectedIndustry={SelectedIndustry}
                onChangedIndustry={updateIndustry}></IndustryDropdown>
            </Col>
          </Row>
          <Row justify='start' style={{ paddingLeft: 20 }}>
            <Col span={24}>
              <PlaceFilterDisplay
                onAutoSuggestPlaceSelected={onAutoSuggestPlaceSelected}
              />
            </Col>
          </Row>
          <Row justify='start' style={{ paddingLeft: 20, marginTop: 15 }}>
            <Col span={24}>
              Dein Lokal nicht gefunden?
              <Button
                shape='round'
                className='header-button'
                style={{ marginLeft: '1em', marginBottom: '0.2em' }}
                onClick={() => history.push('/submitPlace')}>
                Empfehle ein Geschäft
              </Button>
            </Col>
          </Row>

          {Areas && Areas.length > 0 && (
            <div className='neighborhood-card-container-outer'>
              <AreaCards
                areas={Areas}
                hist={history}
                selectedPlace={SelectedPlace}
                suggestedPlaces={SuggestedPlaces}
                onAreaChanged={updateArea}
                selectedArea={SelectedArea}
                onSelectPlace={selectPlace}
              />
            </div>
          )}
        </div>
      </Col>
      <Col className='footer-content'>
        <Row justify='center' align='middle' style={{ textAlign: 'center' }}>
          <Col span={22}>
            <Title level={1} className='under-map-header'>
              Deine Solidarität wird belohnt!
            </Title>
          </Col>
        </Row>
        <Row
          align='middle'
          style={{ textAlign: 'center', marginBottom: 20 }}
          justify='center'>
          <Col xs={22} md={6}>
            <Title level={3}>Betriebe schätzen Dich als Kunde.</Title>
            <p className='info-text'>
              Der Kauf von Wertgutscheinen hilft dem Betrieb mit der Liquidität
              und motiviert mit Aussicht auf neue Kundschaft.
            </p>
          </Col>
          <Col xs={22} md={6}>
            <Title level={3} className='series-header'>
              Wir vernetzen.
            </Title>
            <p className='info-text'>
              Wir bieten Kleinbetrieben eine professionell geführte, einfach
              handhabbare Verkaufsplattform.
            </p>
          </Col>
          <Col xs={22} md={6}>
            <Title level={3}>Du wirst für Deine Unterstützung belohnt.</Title>
            <p className='info-text'>
              Die Gutsein-Gutscheine sind günstig und Du hast schon bezahlt. Das
              Lokal Deiner Wahl wird es Dir danken.
            </p>
          </Col>
        </Row>
        <Row
          align='middle'
          style={{ textAlign: 'center', marginBottom: 20 }}
          justify='center'>
          <Col xs={22} md={19} style={{ textAlign: 'left' }}>
            <Title level={2}>Finden uns gut:</Title>
            <img
              alt={'errich'}
              src={errichEtlin}
              width='35%'
              style={{ float: 'left' }}
            />
            <img
              alt={'zanetti'}
              src={zanetti}
              width='35%'
              style={{ float: 'left' }}
            />
            <a href='https://www.kmu.admin.ch/kmu/de/home/praktisches-wissen/finanzielles/finanzierung/beteiligungsfinanzierung/crowdfunding/unterstuetzungsmassnahmen-seitens-des-privaten-sektors.html'>
              {' '}
              <img
                alt={'schweiz'}
                src={schweizEid}
                width='30%'
                style={{
                  float: 'right',
                  marginTop: '15px',
                }}></img>
            </a>
          </Col>
        </Row>
        <div className='middle-block'>
          <Row justify='center'>
            <Col span={19} style={{ textAlign: 'left' }}>
              <Title level={2}>Und so funktionierts:</Title>
              <p style={{ fontSize: '1.5em', color: 'black' }}>
                «Du brauchst keine App. Bestelle und verkaufe die
                Gutsein-Gutscheine einfach auf der Solidares Webseite. Diese
                werden elektronisch verschickt und können dann bequem und
                unkompliziert zu Hause ausgedruckt oder als PDF gespeichert
                werden»
              </p>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={9} style={{ textAlign: 'left' }}>
              <p className='info-text'>
                <div className='infobox-header customer'>Kunden</div>
                Die Gutsein-Gutscheine können direkt auf unserer Plattform
                bestellt werden und werden nach Abwicklung des Kaufs den Käufern
                elektronisch zugestellt. Drucke als Käufer Deine
                Gutsein-Gutscheine bequem am heimischen PC aus. Die
                Gutsein-Gutscheine bestellst Du ganz einfach oben. Die
                Gutsein-Gutscheine sind mindestens 8 Monate gültig und werden je
                nach Situation verlängert.
              </p>
            </Col>
            <Col span={9} offset={1} style={{ textAlign: 'left' }}>
              <p className='info-text'>
                <div className='infobox-header business'>Geschäfte</div>
                Betriebe, die sich für unsere Plattform interessieren und
                Gutsein-Gutscheine herausgeben möchten, melden sich bei uns über
                das Anmeldeformular an. Als Betrieb wirst Du umgehend
                registriert und erhältst eine Bestätigung, sobald Du
                aufgeschaltet bist (in der Regel kürzer als 2 Tage). Hier geht’s
                zur{' '}
                {
                  <a onClick={() => history.push('/registerPlace')}>
                    Anmeldung Deines Betriebs.
                  </a>
                }
                <br />
                <br />
                Du brauchst kein eigenes Gutschein-System. Solidares macht die
                Gutscheine und Abwicklung für Dich…
                <br />
                <br />
                …und wenn Du noch kein Gutschein-System hast, dann hast Du mit
                Solidares nun eins, ohne zusätzlichen Kosten und Aufwand.
              </p>
            </Col>
          </Row>
        </div>
        <br />
        <Row justify='center'>
          <Col span={19} style={{ textAlign: 'left' }}>
            <Title level={2}>Was wollen wir und wer sind wir?</Title>
            <p className='info-text'>
              Es ist inzwischen allseits bekannt, dass gerade die
              <b> Kleinbetriebe besonders hart getroffen</b> werden von den
              drastischen Betriebsbeschränkungen um den Covid-19 Virus. Geld ist
              wie Benzin, und die Tanks der kleinen Wagen fassen weniger als die
              grossen. Das Geld muss reichen, bis der Spuk vorüber ist. Deshalb
              sind schnelle, praktische Lösungen gefragt, die die Leute
              zusammenbringen, die sich nahe sind, wie zum Beispiel der Wirt und
              der Gast von einem Restaurant.
              <br />
              <br />
              Hinter dem Namen Solidares steht
              <b> ein kleines, initiatives Team</b> von erfahrenen
              Geschäftsleuten, Webdesignern und Kreativen (s. unter{' '}
              <a onClick={() => history.push('/contact')}>Kontakt</a>), denen
              die Kleinbetriebe heute ganz besonders am Herzen liegen. Vielen
              Kleinbetrieben fehlt es an Erfahrung, Knowhow, Zeit und Geld, um
              einen eigenen Netzauftritt zur Geldbeschaffung zu gestalten und zu
              verwalten. <b>Wir wollen</b> hier <b>einspringen</b>, indem wir
              eine schnelle, gute Lösung anbieten.
              <br />
              <br />
              Aber <b>wir denken auch voraus</b>. Gerade in Zeiten grosser
              Unsicherheit ist es wichtig vernetzt zu sein. Menschen erinnern
              sich gerne an andere, die ihnen in schwierigen Zeiten beiseite
              standen. Solidares nutzt die Digitalisierung um Kunden ins
              Geschäft zu bringen. Durch die Gutscheinaktion können somit auch
              nachhaltige Kontakte geknüpft werden.
              <br />
              <br />
              Das alles wollen wir ermöglichen.
            </p>
          </Col>
        </Row>
        <Row justify='center' className='middle-block'>
          <Col span={19}>
            <Title level={2}>In der Presse:</Title>
          </Col>
          <Col span={19}>
            {pressCard({
              title: 'Gutsein-Gutschein für Kleinbetriebe aller Branchen',
              type: 'Zeitungsartikel',
              date: '14.04.2020',
              image: moneyCabLogo,
              link:
                'https://www.moneycab.com/startups/gutsein-gutschein-fuer-kleinbetriebe-aller-branchen/',
            })}
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <Link to='/agb'>AGBs</Link>
          </Col>
        </Row>
      </Col>
    </div>
  );
}

function Header(props) {
  const [FAQVisible, setFAQVisible] = useState(false);
  const history = useHistory();

  const toMain = () => {
    history.push('/');
  };

  return (
    <div className='top-header'>
      <Row align='middle' style={{ maxWidth: '1000px', margin: '0 auto' }}>
        <Col flex={1}>
          <img
            src={img}
            alt='logo'
            style={{
              float: 'left',
              height: '4.7em',
              cursor: 'pointer',
              marginRight: 20,
            }}
            onClick={toMain}></img>
          <span
            className='nav-circle lawyer'
            onClick={() => {
              history.push('/schreibService');
            }}>
            Solides Schreiben
          </span>
          <span
            className='nav-circle gift'
            onClick={() => {
              history.push('/giftCardService');
            }}>
            Gutsein Gutschein
          </span>
        </Col>
        <Col flex='auto'>
          <div style={{ float: 'right' }}>
            <Button
              id='registerBtn'
              shape='round'
              className='header-button'
              style={{
                marginRight: '0.4em',
                marginBottom: '0.2em',
                padding: '0 7px',
              }}
              onClick={() => history.push('/about')}>
              Über uns
            </Button>
            <Popover content={<ShareOptions />}>
              <Button
                shape='round'
                className='header-button'
                style={{ marginRight: '0.4em', marginBottom: '0.2em' }}>
                Sag's weiter!
              </Button>
            </Popover>
            <Button
              shape='round'
              className='header-button'
              onClick={() => {
                history.push('/contact');
              }}>
              Kontakt
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default App;
