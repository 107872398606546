import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Button,
  Typography,
  message,
  Checkbox,
  Col,
} from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './../App.scss';
import { Link } from 'react-router-dom';
import reinhard from './../img/reinhard.jpg';
import bsp1 from './../img/bsp1.png';
import bsp2 from './../img/bsp2.png';
import mamaTierra from './../img/mamaTierra.png';
import andreaHinnen from './../img/andreaHinnen.png';
import anihope from './../img/anihope.png';
import lawyerComic from './../img/lawyerComic.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import errichEtlin from './../img/erichettlin.png';
import zanetti from './../img/zanetti.png';
import schweizEid from './../img/schweizereid.png';
import powerumzug from './../img/Powerumzug.jpg';

const { Title } = Typography;
const { Option } = Select;

export default function LawyerService() {
  const hist = useHistory();

  const infoCard = (props) => {
    return (
      <div>
        <img
          alt='Reinhard-deroo'
          src={reinhard}
          style={{
            float: 'left',
            borderRadius: '50%',
            marginRight: '20px',
            width: '17%',
          }}></img>
        <p
          style={{
            fontSize: '1.2em',
            verticalAlign: 'center',
            paddingTop: '4%',
          }}>
          Dr. iur. Matthias Reinhard-DeRoo, Zürich <br />
          Email: matthias@solidares.ch <br />
          Rufen Sie an: 078 433 08 35
        </p>
      </div>
    );
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0px auto' }}>
      <div className='header-row'>
        <Row justify='center' align='middle'>
          <Col xs={24} md={16}>
            <div
              className='header-underheader'
              style={{
                textAlign: 'center',
              }}>
              <p style={{ fontSize: '2em', marginBottom: 0, paddingTop: 15 }}>
                Ein solides Schreiben verfassen.
                <br />
                Wir unterstützen Sie dabei.
              </p>
              <p style={{ fontSize: '2em', marginBottom: 0, paddingTop: 15 }}>
                Gut. Solid. Solidares.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className='header-row'>
        <Row justify='center' align='middle'>
          <Col xs={24} md={23}>
            <div
              className='header-underheader'
              style={{
                textAlign: 'left',
                padding: '10px',
              }}>
              Liebe Kleinbetriebe, <br /> <br />
              Sie kennen das. Ein Schreiben liegt Ihnen auf dem Magen: Ärger mit
              Kunden, Geschäftspartnern oder Behörden. Nach mühsamem Hin und Her
              am Telefon und im Netz ist die Zeit reif, mit einem kurzen und
              prägnanten Schreiben den Fall in Richtung einer guten Lösung zu
              führen. <br />
              <br /> Wir von Solidares offerieren Ihnen dabei genau die
              Unterstützung welche Sie benötigen – ein solides Schreiben. <br />
              <br />
              Dr.iur. Matthias Reinhard-DeRoo ist Jurist und Verfasser
              anspruchsvoller Texte. Seine Erfahrungen in der Privatwirtschaft
              haben ihm gezeigt, dass ein nüchtern, klar und sachlich
              formuliertes Schreiben oft genügt, um Zeit und Nerven raubende
              Rechtsstreitigkeiten zu verhindern.
            </div>
          </Col>
        </Row>
      </div>
      <div className='header-row'>
        <Row justify='center' align='middle' style={{ padding: '10px 0' }}>
          <Col span={23}>
            <p>
              {' '}
              <b>Was erhalten Sie dafür?</b>
            </p>
          </Col>
          <Col xs={17}>
            <p>
              Geben Sie uns Ihr Anliegen ganz einfach per Anmeldeformular oder
              Telefon bekannt. Solidares offeriert aufgrund dessen dann die
              Verfassung eines individuell formulierten Schreibens, welches Sie
              nur noch ausdrucken, unterschreiben und versenden müssen.
              <br />
              <br />
              Im Idealfall ist die Geschichte damit erledigt, doch selbst wenn
              es weitergeht, bleibt Ihr Schreiben ein nützliches Beweismittel
              für den weiteren Verlauf des Falles.
              <br />
              <br />
              Wir sind uns sicher, dass diese Dienstleistung eine effiziente und
              kostengünstige Alternative zur Lösung eines Konfliktes darstellt,
              fernab von aufwändigen und kostspieligen juristischen Methoden.
            </p>
          </Col>
          <Col span={6}>
            <img src={lawyerComic} alt='comic' style={{ width: '105%' }}></img>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ padding: '10px 0' }}>
          <Col span={23}>
            <p>
              <b>Was kostet Sie das?</b>
            </p>
          </Col>
          <Col xs={23}>
            <p>
              Wir bieten den Schreibservice zu einem günstigen Tarif an, auf den
              wir uns ganz einfach vorgängig einigen. Für Solidares heisst es an
              erster Stelle, erste Unterstützung zu leisten.
              <br />
              Gestartet wird mit einem Telephongespräch von ca. 1/2 Stunde
              (wenns länger geht, gehts länger), in welchem der beabsichtigte
              Inhalt festgelegt wird. 
            </p>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ padding: '10px 0' }}>
          <Col span={23}>
            <p>
              <b>Wie sind Sie dabei?</b>
            </p>
          </Col>
          <Col xs={23}>
            <p>
              Entweder können Sie den Schreibservice über unser Anmeldeformular
              unten beantragen oder Sie rufen Matthias Reinhard-DeRoo direkt an.
            </p>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ padding: '15px 0' }}>
          <Col span={23}>{infoCard()}</Col>
        </Row>
        <Row justify='center' align='middle' style={{ paddingBottom: 40 }}>
          <Col span={23}>
            <p>
              <b>Beispiele aus der Praxis</b>
            </p>
          </Col>
          <Col xs={24} md={11}>
            <Zoom>
              <img alt='bsp1' src={bsp1} width='100%'></img>
            </Zoom>
          </Col>
          <Col xs={24} md={{ offset: 1, span: 11 }}>
            <Zoom>
              <img alt='bsp2' src={bsp2} width='97%'></img>
            </Zoom>
          </Col>
        </Row>
        <Row justify='center' align='middle'>
          <Col span={23}>
            <Title level={2}>Das sagen unsere Kunden:</Title>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ margin: '40px 0' }}>
          <Col md={5} xs={{ flex: 24 }}>
            <img alt='mamaTierra' src={mamaTierra} width='60%'></img>
          </Col>
          <Col md={14} xs={23} style={{ textAlign: 'left' }}>
            <p>
              <i>
                «Vielen Dank für die schnelle Hilfe in schwierigen Momenten.»
              </i>
            </p>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ margin: '40px 0' }}>
          <Col md={5} xs={{ flex: 24 }}>
            <img alt='andreaHinnen' src={andreaHinnen} width='60%'></img>
          </Col>
          <Col md={14} xs={23} style={{ textAlign: 'left' }}>
            <p>
              <i>«Eine sehr sehr gute Idee!»</i>
            </p>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ margin: '40px 0' }}>
          <Col md={5} xs={{ flex: 24 }}>
            <img alt='anihope' src={anihope} width='60%'></img>
          </Col>
          <Col md={14} xs={23} style={{ textAlign: 'left' }}>
            <p>
              <i>
                «Herzlichen Dank. Ich bin sehr dankbar. Alleine hätte ich das
                nie so professionell hinbekommen.»
              </i>
            </p>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ margin: '40px 0' }}>
          <Col md={5} xs={{ flex: 24 }}>
            <img alt='anihope' src={powerumzug} width='60%'></img>
          </Col>
          <Col md={14} xs={23} style={{ textAlign: 'left' }}>
            <p>
              <i>
                «Ich bin sehr zufrieden mit Ihrer Leistung. Sehr professionell
                und fachlich! Dank Ihrer Hilfe hatte ich keinen Ärger im Sommer!
                Der Solidares Schreibservice hat mir sehr geholfen.»
              </i>
            </p>
          </Col>
        </Row>
        <Row justify='center' align='middle' style={{ padding: 40 }}>
          <Col xs={22} md={11}>
            <p
              style={{
                fontSize: '1.5em',
                textAlign: 'center',
                margin: '',
              }}>
              Ein Anruf genügt oder einfach anmelden über unser online
              Anmeldeformular
            </p>
          </Col>
          <Col
            xs={24}
            md={{ span: 11, offest: 2 }}
            style={{ textAlign: 'center' }}>
            <Button
              style={{ marginBottom: 5 }}
              className='button-registration tel'
              onClick={() => alert('test')}>
              <a href='tel:+41784330835'>0784330835</a>
            </Button>
            <Button
              className='button-registration register'
              onClick={() => hist.push('/lawyerSubmit')}>
              Anmeldeformular
            </Button>
          </Col>
        </Row>
        <Row
          align='middle'
          style={{ textAlign: 'center', marginBottom: 20 }}
          justify='center'>
          <Col xs={22} md={23} style={{ textAlign: 'left' }}>
            <Title level={2}>Finden uns gut:</Title>
            <img
              alt={'errich'}
              src={errichEtlin}
              width='35%'
              style={{ float: 'left' }}
            />
            <img
              alt={'zanetti'}
              src={zanetti}
              width='35%'
              style={{ float: 'left' }}
            />
            <a href='https://www.kmu.admin.ch/kmu/de/home/praktisches-wissen/finanzielles/finanzierung/beteiligungsfinanzierung/crowdfunding/unterstuetzungsmassnahmen-seitens-des-privaten-sektors.html'>
              {' '}
              <img
                alt={'schweiz'}
                src={schweizEid}
                width='30%'
                style={{
                  float: 'right',
                  marginTop: '15px',
                }}></img>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}
