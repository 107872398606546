import React, { useState, useEffect } from 'react';
import { Col, Select, Row, Checkbox, Button, Typography } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import dummyCard from '../img/Dummy Gutschein.png';

const { Title } = Typography;
const { Option } = Select;

export default function BuyGiftCard(props) {
  let placeId = props.match.params.id;
  const [Place, setPlace] = useState();
  console.log(placeId);

  useEffect(() => {
    axios
      .get('/api/places/placeById', {
        params: {
          place_id: placeId,
        },
      })
      .then((result) => {
        setPlace(result.data.Place);
      })
      .finally();
  }, []);

  const placeInfo = () => {
    if (Place) {
      console.log(Place);
      return (
        <div
          style={{
            textAlign: 'center',
          }}>
          <p style={{ fontSize: 30, marginBottom: 10 }}>{Place.name}</p>
          <img
            src={Place.imageURL}
            alt='place'
            style={{ maxWidth: 250, margin: '0 auto', borderRadius: 15 }}></img>
          <br />
          <p style={{ fontSize: 22, fontWeight: 800, marginTop: 15 }}>
            <br />
            {Place.address} <br />
            {Place.addressPLZ} {Place.addressArea}
          </p>
          <a style={{ fontSize: 15, fontWeight: 800 }} href={Place.placeURL}>
            {Place.placeURL}
          </a>
          <br />
          <p
            style={{
              textAlign: 'left',
              margin: '20px 15px',
              fontSize: 17,
              whiteSpace: 'pre-line',
            }}>
            {Place.description}
          </p>
        </div>
      );
    }
  };
  const buyGiftCardComp = () => {
    const script = (function(g, i, f, t, u, p, s) {
      g[u] =
        g[u] ||
        function() {
          (g[u].q = g[u].q || []).push(arguments);
        };
      p = i.createElement(f);
      p.async = 1;
      p.src = t;
      s = i.getElementsByTagName(f)[0];
      s.parentNode.insertBefore(p, s);
    })(
      window,
      document,
      'script',
      'https://cdn.giftup.app/dist/gift-up.js',
      'giftup',
    );
    return (
      <>
        <div
          data-site-id={Place.giftUpId}
          data-platform='Other'
          className='gift-up-target'></div>
        <script type='text/javascript'>{script}</script>
      </>
    );
  };

  if (Place && Place.placeID === 'ChIJGaK-SZcLkEcRA9wf5_GNbuY') {
    // showcase
    return (
      <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
        <div className='header-row subpage'>
          <Row justify='center' align='middle'>
            <Col xs={22} md={22}>
              <Title
                level={1}
                className='header-title-text'
                style={{ fontSize: '2em', padding: '10px 0' }}>
                Gutsein - Gutschein
              </Title>
            </Col>
          </Row>
        </div>

        <div className='main-results' style={{ padding: 15 }}>
          <Row justify='center'>
            <Col xs={24} md={12}>
              <div
                style={{
                  textAlign: 'center',
                }}>
                <p style={{ fontSize: 30, marginBottom: 10 }}>{Place.name}</p>
                <img
                  src={Place.imageURL}
                  alt='place'
                  style={{
                    maxWidth: 250,
                    margin: '0 auto',
                    borderRadius: 15,
                  }}></img>
                <br />
                <a
                  style={{ fontSize: 15, fontWeight: 800, marginTop: 15 }}
                  href={Place.placeURL}>
                  {Place.placeURL}
                </a>
              </div>
              <p
                style={{
                  fontSize: 20,
                  margin: 30,
                  marginTop: 50,
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  borderRadius: 15,
                  padding: 6,
                }}>
                Wir zeigen hier, wie Dein Auftritt sowie der Gutsein-Gutschein
                bei Solidares.ch ausschaut. <br />
                Mach mit und melde Dich heute noch an. <br /> <br /> Ihr
                Solidares Team
              </p>
            </Col>
            <Col xs={24} md={12}>
              <img alt='logo' src={dummyCard} style={{ width: '90%' }}></img>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
        <div className='header-row subpage'>
          <Row justify='center' align='middle'>
            <Col xs={22} md={22}>
              <Title
                level={1}
                className='header-title-text'
                style={{ fontSize: '2em', padding: '10px 0' }}>
                Gutsein - Gutschein
              </Title>
            </Col>
          </Row>
        </div>

        <div className='main-results' style={{ padding: 15 }}>
          <Row justify='center'>
            <Col xs={24} md={12}>
              {placeInfo()}
            </Col>
            <Col xs={24} md={12}>
              {Place && buyGiftCardComp()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
