import React from 'react';
import { Button, Row, Col, Typography, Table } from 'antd';
import Skeleton from 'react-loading-skeleton';
import { CallToActionButton } from './CallToActionButton';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import fbImage from '../img/fb.jpeg';

export class SuggestedPlaceCards extends React.Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();
  }

  render() {
    const GoogleMapExample = withGoogleMap((props) => {
      const markers = (suggs) => {
        return suggs
          .filter((f) => f.hasGoogleEntry)
          .map((sug) => {
            return (
              <Marker
                key={sug.placeID}
                title={sug.name}
                label={{
                  text: sug.name,
                  color: 'black',
                  fontSize: '20px',
                  fontWeight: '800',
                }}
                onClick={(event) => {}}
                position={{ lat: sug.lat, lng: sug.lng }}></Marker>
            );
          });
      };

      return (
        <GoogleMap
          center={{ lat: props.lat, lng: props.lng }}
          defaultZoom={13}
          ref={this.map}>
          {markers(props.suggestions)}
        </GoogleMap>
      );
    });

    const { Title } = Typography;
    var suggestedPlaceCards;
    if (this.props.suggestedPlaces && this.props.suggestedPlaces.length) {
      suggestedPlaceCards = this.props.suggestedPlaces.map((suggestion) => (
        <div
          key={suggestion.placeID}
          className={
            this.props.selectedPlace &&
            suggestion.placeID === this.props.selectedPlace.placeID
              ? 'suggested-place place-selected'
              : 'suggested-place'
          }
          onClick={() => {
            this.props.onSelectPlace(suggestion);

            if (suggestion.hasGoogleEntry) {
              this.map.current.panTo({
                lat: suggestion.lat,
                lng: suggestion.lng,
              });
            }
          }}>
          <Row
            style={{
              backgroundSize: 'cover',
              position: 'relative',
              backgroundImage: 'url(' + suggestion.imageURL + ')',
              minHeight: '80px',
            }}></Row>
          <Row style={{ minHeight: '88px' }}>
            <Title
              className='suggestion-title'
              style={{
                textAlign: 'center',
                width: '100%',
                wordWrap: 'break-word',
                marginTop: '12px',
                padding: '0px 6px',
              }}
              level={4}>
              {suggestion.name}
            </Title>
            <div style={{ margin: '0px auto' }}>
              <Button
                className='button-buy'
                onClick={() =>
                  this.props.hist.push({
                    pathname: '/buyGiftCard/' + suggestion.placeID,
                  })
                }>
                Gutsein Gutschein
              </Button>
            </div>
          </Row>
        </div>
      ));
    }

    return (
      <div ref={this.props.passRef}>
        <section className='suggestions-container'>
          <Row style={{ justifyContent: 'center' }}>
            <Col xs={22} md={12}>
              <div style={{ maxHeight: 600, overflowX: 'auto' }}>
                {suggestedPlaceCards}
              </div>
            </Col>
            <Col xs={22} md={{ span: 11, offset: 1 }}>
              <div>
                <GoogleMapExample
                  suggestions={this.props.suggestedPlaces}
                  lat={
                    this.props.selectedPlace &&
                    this.props.selectedPlace.hasGoogleEntry
                      ? this.props.selectedPlace.lat
                      : this.props.selectedArea.lat
                  }
                  lng={
                    this.props.selectedPlace &&
                    this.props.selectedPlace.hasGoogleEntry
                      ? this.props.selectedPlace.lng
                      : this.props.selectedArea.lng
                  }
                  containerElement={<div className='map-container' />}
                  mapElement={
                    <div style={{ height: `100%`, background: 'black' }} />
                  }
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <a href='https://www.facebook.com/Solidares/'>
                  <img
                    src={fbImage}
                    alt='facebook'
                    width='50%'
                    style={{
                      display: 'block',
                      margin: 'auto',
                      verticalAlign: 'center',
                    }}></img>
                </a>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}
