import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Button,
  Typography,
  message,
  Col,
} from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import './formStyle.scss';
import GoogleAddressInput from './CustomInputs';

const { Title } = Typography;
const { Option } = Select;

export default function SubmitPlace() {
  const [Industries, setIndustries] = useState([]);
  const [Areas, setAreas] = useState([]);

  useEffect(() => {
    axios.get('/api/places/industries').then((result) => {
      setIndustries(result.data.Industries);
    });
    axios.get('/api/places/areas', {}).then(
      (result) => {
        setAreas(result.data.Areas);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 5,
      },
    },
  };

  const SubmitForm = (props) => {
    const [form] = Form.useForm();
    const hist = useHistory();

    const onFinish = (values) => {
      axios
        .post('/api/places/submit_new_place', {
          ...values,
          submitterName:
            values.submitterLastName + ', ' + values.submitterFirstName,
          plz: values.address.plz,
          manualAddress: values.address.street,
          addressArea: values.address.area,
        })
        .then((response) => {
          message.success('Vorschlag abgesendet');
          hist.push('/');
        })
        .catch((error) => {
          message.error('Fehler beim absenden!');
          console.log(error);
        });
    };

    return (
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        initialValues={{
          associated: false,
        }}
        scrollToFirstError>
        <Form.Item
          name='placeName'
          label='Firmen Name'
          rules={[
            {
              required: true,
              message: 'Bitte den Namen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item name='placeAddress' label='Finde Deinen Betrieb'>
          <GoogleAddressInput></GoogleAddressInput>
        </Form.Item>
        <Form.Item label='Address'>
          <Input.Group compact>
            <Form.Item
              name={['address', 'street']}
              noStyle
              rules={[
                { required: true, message: 'Bitte die Straße eintragen!' },
              ]}>
              <Input style={{ width: '50%' }} placeholder='Straße' />
            </Form.Item>
            <Form.Item
              name={['address', 'plz']}
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Bitte die PLZ eintragen!',
                },
              ]}>
              <Input style={{ width: '20%' }} placeholder='PLZ' />
            </Form.Item>
            <Form.Item
              name={['address', 'area']}
              noStyle
              rules={[{ required: true, message: 'Bitte den Ort eintragen!' }]}>
              <Input style={{ width: '30%' }} placeholder='Ort' />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item name='placeWebsite' label='Firmen Webseite'>
          <Input />
        </Form.Item>

        <Form.Item
          name='area'
          label='Kanton'
          rules={[
            {
              required: true,
              message: 'Bitte einen Kanton auswählen!',
            },
          ]}>
          <Select>
            {props.areas.map((ar) => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name='industry'
          label='Kategorie'
          rules={[
            {
              required: true,
              message: 'Bitte eine Kategorie auswählen!',
            },
          ]}>
          <Select>
            {props.industries.map((ar) => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='submitterFirstName'
          label='Dein Vorname'
          rules={[
            {
              required: true,
              message: 'Bitte Deinen Vornamen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='submitterLastName'
          label='Dein Name'
          rules={[
            {
              required: true,
              message: 'Bitte Deinen Nachnamen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='email'
          label='Deine E-mail'
          rules={[
            {
              type: 'email',
              message: 'Die E-Mail ist nicht valide',
            },
            {
              required: true,
              message: 'Bitte Deine E-Mail eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='associated'
          valuePropName='checked'
          {...tailFormItemLayout}>
          <Checkbox>Ich bin mit dem Geschäft verbunden.</Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit'>
            Bestätigen
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div
      style={{
        width: '80%',
        maxWidth: '1100px',
        margin: '0 auto',
        marginTop: 100,
      }}>
      <div className='header-row subpage'>
        <Row justify='center' align='middle'>
          <Col xs={24} md={22}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '2em', padding: '10px 0' }}>
              Empfehle ein Geschäft
            </Title>
            <div
              className='header-underheader'
              style={{
                textAlign: 'center',
              }}>
              Wenn Du ein Gast bist, der ein Geschäft, das Du liebst,
              unterstützen möchte, kannst Du die Informationen dieses Geschäfts
              in dieses Verzeichnis eintragen und andere dazu ermutigen,
              Geschenkkarten zu kaufen, um Dein Geschäft zu unterstützen.
              Nachdem Du die Informationen des Restaurants übermittelt hast,
              müssen wir die Angaben bestätigen, bevor wir sie in das
              Verzeichnis aufnehmen können. Dies kann bis zu 48 Stunden dauern.
            </div>
          </Col>
        </Row>
      </div>

      <div className='main-results' style={{ padding: 15 }}>
        <SubmitForm areas={Areas} industries={Industries}></SubmitForm>
      </div>
    </div>
  );
}
