import React, { useState, useEffect } from 'react';
import { Row, Typography, Col } from 'antd';
const { Title } = Typography;

export default function About(props) {
  return (
    <div
      style={{
        width: '80%',
        maxWidth: '1100px',
        margin: '0 auto',
        marginTop: '100px',
      }}>
      <div className='header-row'>
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '4em' }}>
              Über Uns
            </Title>
          </Col>
        </Row>
      </div>
      <Row style={{ backgroundColor: 'white', borderRadius: 20, padding: 25 }}>
        <br />
        <b style={{ fontSize: 20, marginBottom: 25 }}>
          Der Verein Solidares besteht aus erfahrenen Geschäftsleuten und
          Kreativen, denen Kultur und das Kleingewerbe am Herzen liegen, und die
          ihr Können und ihre Kräfte zur Verfügung stellen um die Betriebs- und
          Arbeitswelt etwas aufzufrischen. Namentlich handelt es sich um <br />{' '}
        </b>
        <br />
        <br />
        <br />
        <div style={{ fontSize: 17, marginBottom: 45 }}>
          Remo Stieger M.A. HSG, Kilchberg/ZH (Geschäftsidee/-konzept und
          Co-Produzent),
          <br />
          <br />
          Dr. iur. Matthias Reinhard-DeRoo, Zürich (Co-Produzent, rechtliche und
          allgemeine Unterstützung),
          <br />
          <br />
          Matthias Lay, Diebach (Co-Produzent und Web-Entwicklung).
        </div>
      </Row>
    </div>
  );
}
