import React from 'react';
import { Button, Col, Popover, Row, Typography } from 'antd';
import { useHistory } from 'react-router';
import logo from './../img/SolidaryandFood.png';
import logoPen from './../img/logo_stift.jpeg';
import comic from './../img/cartoonCook.png';
import schweizereid from '../img/schweizereid.png';
import cornerLeft from './../img/corner_left.png';
import cornerRight from './../img/corner_right.png';
import moneyCabLogo from './../img/moneyCabLogo.png';
const { Title } = Typography;

export default function LandingPage(props) {
  const hist = useHistory();

  const pressCard = (props) => {
    return (
      <div>
        <img src={props.image} width='130px' alt='press logo'></img> <br />
        <div style={{ fontSize: 18, marginBottom: 5 }}>{props.title}</div>
        {props.type}
        <br />
        {props.date}
        <br />
        <a href={props.link}>Hier lesen</a>
      </div>
    );
  };

  return (
    <>
      <div className='landingpage-new'>
        <div className='landingpage-new-content'>
          <img
            src={cornerLeft}
            alt='corner'
            style={{
              width: '17vw',
              position: 'fixed',
              top: 90,
              left: 0,
            }}></img>
          <img
            src={cornerRight}
            alt='corner'
            style={{
              width: '17vw',
              zIndex: 1,
              position: 'fixed',
              bottom: 0,
              right: 0,
            }}></img>
          {/* <div style={{ position: 'absolute', left: '50%', zIndex: 1 }}>
          <div
            style={{ position: 'relative', left: '-50%', textAlign: 'center' }}>
            <img src={logo} alt='logo' width='80%'></img>
          </div>
        </div> */}
          <Row justify='end' align='top'>
            <Col xs={{ span: 24 }} md={{ span: 8, pull: 1 }}>
              <Title
                level={1}
                className='header-title-text'
                style={{ fontSize: '4em', textAlign: 'left' }}>
                For you KMU
              </Title>
              <p
                style={{
                  fontSize: 20,
                  padding: '0 0 0px 0px',
                  marginTop: '-5%',
                }}>
                Für <b className='text-or'>K</b>leine und{' '}
                <b className='text-or'>M</b>ittlere <b className='text-or'>U</b>
                nternehmen sowie <b className='text-bl'>K</b>unst,{' '}
                <b className='text-bl'>M</b>usik, <b className='text-bl'>U</b>nd
                mehr… <br />
              </p>
            </Col>
          </Row>
          <Row justify='center' style={{ marginBottom: 40 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img src={logo} alt='logo' width='50%'></img>
            </Col>
          </Row>
          <Row justify='start'>
            <Col xs={24} md={{ span: 14, push: 1 }}>
              <p>
                Der Verein Solidares wurde von erfahrenen Berusfsleuten aus den
                Bereichen Wirtschaft, Recht und Kultur gegründet mit dem Zweck,
                kleinen und mittleren Unternehmen sowie Kulturschaffenden
                originelle, solide Lösungen und interessante Gedankenanstösse
                anzubieten.
              </p>
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: 200, marginBottom: 200 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Title level={2} style={{ fontSize: 44 }}>
                Wir setzen um, worüber so viel gesprochen wird.
                <br />
                <b className='text-bl'>Über Solidarität.</b>
              </Title>
            </Col>
          </Row>
          <div className='lawyer-box'>
            <Row justify='center' style={{ marginTop: 200 }}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Title level={2}>AKTUELL</Title>
              </Col>
            </Row>
            <Row
              justify='center'
              style={{
                alignItems: 'center',
                textAlign: 'center',
                marginBottom: 100,
              }}>
              <Col xs={24} md={10}>
                <Title level={2}>
                  "Solides Schreiben" <br />
                  Der Solidares Schreibservice
                </Title>
                <Button
                  type='primary'
                  onClick={() => hist.push('/schreibService')}>
                  Jetzt loslegen
                </Button>
              </Col>
              <Col xs={24} md={10}>
                <img src={logoPen} alt='stift' width='60%'></img>
              </Col>
            </Row>
            <Row
              justify='center'
              style={{
                alignItems: 'center',
                textAlign: 'center',
                marginTop: 100,
              }}>
              <Col xs={24} md={8}>
                <img src={comic} alt='cartoon' width='60%'></img>
              </Col>
              <Col xs={24} md={10}>
                <Title level={2}>
                  “Gutsein-Gutschein” <br />
                  Gut. Solid. Solidares
                </Title>
                <Button
                  type='primary'
                  onClick={() => hist.push('/giftCardService')}>
                  Jetzt loslegen
                </Button>
              </Col>
            </Row>
          </div>
          <Row
            justify='center'
            style={{
              marginTop: 130,
              textAlign: 'center',
              alignItems: 'center',
            }}>
            <Col span={24}>
              <Title level={2}>In der Presse:</Title>
            </Col>
            <Col span={24}>
              {pressCard({
                title: 'Gutsein-Gutschein für Kleinbetriebe aller Branchen',
                type: 'Zeitungsartikel',
                date: '14.04.2020',
                image: moneyCabLogo,
                link:
                  'https://www.moneycab.com/startups/gutsein-gutschein-fuer-kleinbetriebe-aller-branchen/',
              })}
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: 200 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Title level={2}>Demnächst</Title>
            </Col>
          </Row>
          <Row>
            <div className='pageselect small gift'>
              "Kennwort Solidares"
              <br />
              <div style={{ fontSize: 16, marginTop: 10 }}>
                Solidares vernetzt, fördert und bereichert die Schweizer Kultur
              </div>
            </div>
            <div
              className='pageselect small gift'
              onClick={() => window.open('https://youtu.be/0kjBHT1I0vE')}>
              Trinkgeld Macht Fröhlich
            </div>
          </Row>
          <Row
            justify='center'
            align='middle'
            className='content'
            style={{ marginTop: '100px' }}>
            <Col
              xs={{ span: 19, offset: 0, order: 1 }}
              md={{ span: 10, offset: 6, order: 0 }}>
              <img src={schweizereid} width='250rem'></img>
            </Col>
            <Col
              xs={{ span: 18, pull: 0, order: 0 }}
              md={{ span: 7, pull: 4, order: 1 }}>
              Das Staatssekretariat für Wirtschaft SECO hat{' '}
              <a
                href='https://www.kmu.admin.ch/kmu/de/home/praktisches-wissen/finanzielles/finanzierung/beteiligungsfinanzierung/crowdfunding/unterstuetzungsmassnahmen-seitens-des-privaten-sektors.html
'>
                Solidares als Unterstützungsmassnahme geprüft und anerkannt
              </a>
              <br />
              <br />
            </Col>
          </Row>
        </div>
      </div>

      <Row
        style={{
          width: '100%',
          height: '7em',
          backgroundColor: '#a6a6a6',
          marginTop: 50,
          zIndex: 2,
          paddingTop: '10px',
          color: 'whitesmoke',
          position: 'absolut',
          bottom: 0,
          textAlign: 'center',
        }}
        justify='space-between'>
        <Col span={8}>
          BENÖTIGEN SIE HILFE?
          <br />
          Rufen Sie an:
          <br />
          0784330835
        </Col>
        <Col span={8}>
          Kontaktieren Sie uns mit einer Nachricht <br />
          <a href={'mailto:kontakt@solidares.ch'}>kontakt@solidares.ch</a>
        </Col>
        <Col span={8}>© 2020 – Verein Solidares</Col>
      </Row>
    </>
  );
}
