import React, { useState, useEffect } from 'react';
import { Typography, Row } from 'antd';
import axios from 'axios';
import { SuggestedPlaceCards } from './SuggestedPlaceCards';
const { Title } = Typography;

export class AreaCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      fetchOffset: 0,
      windowWidth: 0,
      isPlacesLoading: false,
      moreAvailable: true,
    };
    this.ref = React.createRef();
    props.onAreaChanged(undefined, undefined);
  }
  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.currentArea !== this.props.currentArea) {
      this.setState({
        loading: true,
        suggestedPlaces: null,
        offset: 0,
      });
    }
  };
  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCardsForCurrentPage() {
    const areaCards = this.props.areas.slice(this.state.offset).map(area => {
      return (
        <div
          key={area.key}
          className='neighborhood-card'
          style={{ textAlign: 'center' }}>
          <div
            className={
              'neighborhood-card-image' +
              (this.props.selectedArea && this.props.selectedArea === area.key
                ? ' neighborhood-card-image-selected'
                : '')
            }
            onClick={event => {
              this.props.onAreaChanged(area.key);
            }}>
            <div
              className={
                'neighborhood-card-title' +
                (this.props.selectedArea && this.props.selectedArea === area.key
                  ? ' neighborhood-card-title-selected'
                  : '')
              }>
              {area.name}
            </div>
          </div>
        </div>
      );
    });
    return areaCards;
  }
  render() {
    return (
      <>
        <Row justify='center'></Row>
        {this.props.areas && (
          <section
            className='neighborhood-card-container'
            style={{
              maxWidth:
                this.state.windowWidth >= 576
                  ? this.state.windowWidth - 200
                  : this.state.windowWidth,
              marginBottom: 15,
            }}>
            {this.state.offset !== 0 && (
              <a
                className={
                  'neighborhood-card-arrow neighborhood-card-arrow-left ' +
                  (this.state.offset >= this.props.areas.length - 1
                    ? 'neighborhood-card-arrow-disabled'
                    : '')
                }
                onClick={event => {
                  this.setState({
                    offset: Math.max(0, this.state.offset - 1),
                  });
                }}>
                {'‹'}
              </a>
            )}
            {this.getCardsForCurrentPage()}
            {this.state.offset < this.props.areas.length - 1 && (
              <a
                className={
                  'neighborhood-card-arrow neighborhood-card-arrow-right ' +
                  (this.state.offset >= this.props.areas.length - 1
                    ? 'neighborhood-card-arrow-disabled'
                    : '')
                }
                onClick={event => {
                  console.log(this.state.offset);
                  console.log(this.props.areas.length);
                  this.setState({
                    offset: Math.min(
                      this.props.areas.length - 2,
                      this.state.offset + 1,
                    ),
                  });
                }}>
                ›
              </a>
            )}
          </section>
        )}
        <SuggestedPlaceCards
          passRef={this.ref}
          onSelectPlace={this.props.onSelectPlace}
          selectedPlace={this.props.selectedPlace}
          suggestedPlaces={this.props.suggestedPlaces}
          selectedArea={this.props.areas.find(
            x => x.key === this.props.selectedArea,
          )}
          moreAvailable={this.state.moreAvailable}
          hist={this.props.hist}
        />
      </>
    );
  }
}

export default AreaCards;
