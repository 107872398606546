import React, { useState, useEffect } from 'react';
import { Row, Typography, Col } from 'antd';
const { Title } = Typography;

export default function AGB(props) {
  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <div className='header-row'>
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '4em' }}>
              Allgemeine Geschäftsbedinungen (AGBs)
            </Title>
          </Col>
        </Row>
      </div>
      <Row
        style={{
          backgroundColor: 'white',
          borderRadius: 20,
          padding: 15,
          marginBottom: 10,
        }}>
        <b>ZWECK</b>
        <p>
          "Gutsein Gutschein" (nachfolgend "GG") ist eine Geschäftsinitiative
          von Solidares, welche zum Ziel hat, kleinen Unternehmen, welche im
          Zusammenhang mit COVID 19 in finanzielle Bedrängnis geraten sind zu
          helfen, rasch Liquidität zu schaffen. Bei den Herausgebern von GG
          handelt es sich um eine ad hoc Formation von Solidares, bestehend aus
          erfahrenen Geschäftsleuten und Kreativen, denen das Kleingewerbe am
          Herzen liegt, und die ihr Können und ihre Kräfte zur Verfügung
          stellen. Namentlich handelt es sich um Remo Stieger M.A.HSG,
          Kilchberg/ZH, (Geschäftsidee/-konzept und operative Umsetzung, Dr.iur.
          Matthias Reinhard-DeRoo, Zürich (Co-Produzent, rechtliche und
          allgemeine Unterstützung). Adrian Oostlander M.A.HSG, Neuhausen/SH
          (Co-Produzent, Finanzen und Operatives) und Matthias Lay, Diebach
          (Web-Entwicklung). Als Entschädigung für ihren Aufwand inkl.
          Entschädigungen an Drittanbietern beziehen sie einen kleinen
          Gewinnanteil pro verkauften Gutschein.
        </p>
        <b>Gutscheine</b>
        <p>
          Solidares stellt Betrieben eine Plattform zur Verfügung, auf welcher
          jene ihren Kunden professionell vorformatierte Gutscheine zu einem
          attraktiven Preis anbieten können. Es gibt vier verschiedene
          Gutscheine. <br />
          - CHF 150 zu 10%, 15% oder 20% Rabatt
          <br />
          - CHF 100 zu 10%, 15% oder 20% Rabatt
          <br />
          - CHF 50 zu 10%, 15% oder 20% Rabatt
          <br />
          - CHF 20 zu 10%, 15% oder 20% Rabatt
          <br />
          Sämtliche Rechte an der Geschäftsinitiative, am Design und der
          Konzeption der Gutscheine bleiben Solidares vorbehalten. Den Betrieben
          ist es unbenommen, in eigener Initiative für die Gutscheine zu werben.
          Die Gutscheine dienen ausschliesslich als Zahlungsbelege und geben
          keine Auskunft über die Qualität des Betriebes. Eine allfällige
          Rückerstattung des Gutscheinbetrages ist direkt zwischen dem Betrieb
          und dem Endkunden zu regeln. Ein Rückgriff auf Solidares ist
          ausgeschlossen. Die Gutscheine sind übertragbar. Sie sind für
          mindestens 8 Monate nach Kauf gültig und werden je nach Situation
          verlängert.
        </p>
        <b>Abwicklung</b>
        <p>
          Ein Betrieb, der sich an der Aktion beteiligen will, meldet sich auf
          der Plattform mit Namen des Betriebs und Kontaktdaten an. Solidares
          bestätigt umgehend den Eingang und erfasst den Betrieb in ihrer
          Datenbank. Interessierte Kunden bestellen Gutscheine für den Betrieb
          ihrer Wahl auf der Plattform. Sie bezahlen via Kreditkarte oder via
          E-banking mit dem Namen unter Anmerkung des begünstigten Betriebs.
          Solidares unterstützt den Betrieb und den Kunden beim zeitnahen
          Zahlungsfluss. Nach Zahlungseingang sendet die Plattform den
          elektronischen Gutschein an den Kunden. Die Plattform informiert den
          Betrieb über die getätigten Transaktionen. Am Ende der Initiative
          erhält der Betrieb einen Gesamtüberblick für die eigene Buchhaltung
          und MwSt Berechnung. Die Plattform ist lediglich für die MwSt
          Abrechnung der erhaltenen Komissionen zuständig.
        </p>
        <b>Rechte und Pflichten des Betriebs</b>
        <p>
          Der Betrieb ist berechtigt zur betriebsrelevanten Verwendung über die
          durch den Verkauf der Gutscheine entstandenen Einnahmen. Er ist
          Solidares gegenüber keine Rechenschaft schuldig. Der Betrieb ist
          verpflichtet zu Dienstleistungen im Werte des auf dem Gutschein
          erwähnten Betrages und ist damit einverstanden, dass diese Gutscheine
          mit einem Abschlag von bis zu 20% angeboten werden. Die Qualität der
          Dienstleistung ist in der alleinigen Verantwortung des Betriebes. Die
          Handhabe von nur teilbetragsweise genutzten Gutscheinen sowie
          allfällige Rückerstattungen sind Sache des Betriebes. Sofern der
          Betrieb Bilddateien für die Auflistung auf der Plattform stellt,
          garantiert der Betrieb Solidares, über die Rechte an den Bilddateien
          zu verfügen, und dass, das durch die Verwendung des Bildmaterials
          weder gesetzliche Vorschriften, noch Rechte Dritter verletzt werden.
          Wenn Behörden wegen Gesetzesverletzungen oder Dritte Ansprüche gegen
          Solidares wegen Verwendung der Bilddateien stellen, verpflichtet sich
          der Betrieb zur Schadloshaltung und Freistellung von Solidares von
          sämtlichen Ansprüchen, Schäden und Kosten (inklusive Gerichts- und
          Anwaltskosten). Solidares ist in einem solchen Fall zur Offenlegung
          der Identität des Betriebes verpflichtet.
        </p>
        <b>Rechte und Pflichten von Solidares und GG</b>
        <p>
          Die Gestaltung der Plattform, der Gutscheine und aller anderen mit dem
          Erscheinungsbild der Initiative verbundenen Elemente (Werbetexte, Logo
          etc.) gehören ausschliesslich zum geistigen Eigentum von Solidares.
          Solidares verpflichtet sich zum einwandfreien Plattformbetrieb- und
          unterhalt. Im Falle von Pannen hilft sie Kunden und Betrieben bei der
          Suche nach Lösungen, welche die Transaktionen rasch auf anderem Wege
          ermöglichen.
        </p>
        <b>Rechte und Pflichten des Kunden</b>
        <p>
          Kunden sind berechtigt zum Bezug von Dienstleistungen im Umfang des
          Gutscheinbetrages. Der Kunde verpflichtet sich, den Gutschein
          ausschliesslich zur Konsumation zu verwenden. Jede zweckfremde
          Verwendung, insbesondere der Massenkauf zum Weiterhandeln, ist
          untersagt und kann rechtliche Schritte nach sich ziehen. Mit Ablauf
          der Gültigkeit des Gutscheins (siehe oben) erlischt der Anspruch auf
          Dienstleistungen. Allfällige Unzufriedenheit mit der Dienstleistung
          ist direkt mit dem Betrieb zu regeln. Allfällige Friktionen bei der
          Abwicklung mit der Kreditkartengesellschaft sind ebenfalls direkt mit
          jener aufzunehmen.
        </p>
        <b>Geschäftsgeheimnis / Datenschutz</b>
        <p>
          Solidaris und GG ist berechtigt zur Aufnahme von Kunden- und
          Betriebsdaten, welche für die Erledigung der Verkäufe zwingend
          notwendig sind. Betreffend der einzelnen Transaktionen verpflichtet
          sie sich zur Vertraulichkeit. Die Bewerbung der Plattform unter
          Erwähnung angeschlossener Betriebe zu Werbezwecken ist ausdrücklich
          gestattet. Die Bewerbung der Plattform unter Erwähnung von Kunden
          braucht hingegen dessen Zustimmung.
        </p>
        <b>Anwendbares Recht / Gerichtsstand</b>
        <p style={{ width: '100%' }}>
          Es gilt Schweizer Recht. Zuständig sind die Gerichte von Zürich.
        </p>
      </Row>
    </div>
  );
}
