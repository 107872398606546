import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Button,
  Typography,
  message,
  Checkbox,
  Col,
} from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './formStyle.scss';
import TextArea from 'antd/lib/input/TextArea';

const { Title } = Typography;

export default function RegisterPlace() {
  const onSubmit = (data) => {
    console.log(data);
  }; // your form submit function which will invoke after successful validation

  const functions = [
    'Besitzer',
    'Manager',
    'Chef',
    'Berater',
    'Operations/IT',
    'Lieferant',
    'Technologie Partner',
    'Anderes',
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 5,
      },
      sm: {
        span: 24,
        offset: 5,
      },
    },
  };
  const longLabelFormItemLayout = {
    labelCol: {
      xs: { span: 8, pull: 0 },
      sm: { span: 12, pull: 1 },
      md: { span: 12, pull: 2 },
      lg: { span: 12, pull: 3 },
    },
    wrapperCol: {
      xs: {
        span: 6,
        pull: 0,
      },
      sm: {
        span: 6,
        pull: 1,
      },
      md: {
        span: 6,
        pull: 2,
      },
      lg: {
        span: 6,
        pull: 3,
      },
    },
  };

  const SubmitForm = (props) => {
    const [form] = Form.useForm();
    const hist = useHistory();

    const onFinish = (values) => {
      console.log(values);

      axios
        .post('/api/places/lawyerSendMail', {
          ...values,
          formatAddress:
            values.address.street +
            ' ' +
            values.address.plz +
            ' ' +
            values.address.area,
        })
        .then((response) => {
          message.success('Anfrage abgesendet');
          hist.push('/');
        })
        .catch((error) => {
          message.error('Fehler beim absenden!');
          console.log(error);
        });
    };

    return (
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        scrollToFirstError>
        <Form.Item
          name='name'
          label='Firmen Name'
          rules={[
            {
              required: true,
              message: 'Bitte den Namen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label='Adresse' required='true'>
          <Input.Group compact>
            <Form.Item
              name={['address', 'street']}
              noStyle
              rules={[
                { required: true, message: 'Bitte die Straße eintragen!' },
              ]}>
              <Input style={{ width: '50%' }} placeholder='Straße' />
            </Form.Item>
            <Form.Item
              name={['address', 'plz']}
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Bitte die PLZ eintragen!',
                },
              ]}>
              <Input style={{ width: '20%' }} placeholder='PLZ' />
            </Form.Item>
            <Form.Item
              name={['address', 'area']}
              noStyle
              rules={[{ required: true, message: 'Bitte den Ort eintragen!' }]}>
              <Input style={{ width: '30%' }} placeholder='Ort' />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item name='website' label='Firmen Webseite'>
          <Input />
        </Form.Item>

        <Form.Item
          name='vname'
          label='Ihr Vorname'
          rules={[
            {
              required: true,
              message: 'Bitte Ihren Vornamen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='lname'
          label='Ihr Name'
          rules={[
            {
              required: true,
              message: 'Bitte Ihren Nachnamen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='mail'
          label='Ihre E-mail'
          rules={[
            /* {
              type: 'email',
              message: 'Die E-Mail ist nicht valide',
            }, */
            {
              required: true,
              message: 'Bitte tragen Sie Ihre E-Mail ein!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item name='tel' label='Tel. Nr.'>
          <Input />
        </Form.Item>
        <Form.Item
          name='func'
          label='Ihre Funktion'
          rules={[
            {
              required: true,
              message: 'Bitte wählen Sie Ihre Funktion aus',
            },
          ]}>
          <Select>
            {functions.map((func) => {
              return <Select.Option value={func}>{func}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item name='request' label='Anfrage'>
          <TextArea rows={10}></TextArea>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit'>
            Anfrage Senden
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <div className='header-row subpage'>
        <Row justify='center' align='middle'>
          <Col xs={22} md={22}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '2em', padding: '10px 0' }}>
              Senden Sie uns Ihre Anfrage
            </Title>
            <div
              className='header-underheader'
              style={{
                textAlign: 'left',
              }}>
              Geben Sie uns Ihr Anliegen einfach per Anmeldeformular bekannt.
              Wir werden uns umgehend bei Ihnen melden und freuen uns Ihnen bei
              der Verfassung eines individuell formulierten Schreibens zu
              unterstützen. Im Rahmen der Solidares-Promotion bieten wir diesen
              Service für alle Kleinbetriebe kostenlos an. Nutzen Sie die
              Möglichkeit noch heute.
              <br />
            </div>
          </Col>
        </Row>
      </div>
      <div className='main-results' style={{ padding: 15 }}>
        <SubmitForm></SubmitForm>
      </div>
    </div>
  );
}
