import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Form,
  Select,
  Input,
  Radio,
  Typography,
  Col,
  Row,
  Descriptions,
  Badge,
} from 'antd';
const { Title } = Typography;
const { TextArea } = Input;
const functions = [
  'Besitzer',
  'Manager',
  'Chef',
  'Berater',
  'Operations/IT',
  'Lieferant',
  'Technologie Partner',
  'Anderes',
];

function StepOne(props) {
  const [Industries, setIndustries] = useState([]);

  useEffect(() => {
    axios.get('/api/places/industries').then((result) => {
      setIndustries(result.data.Industries);
    });
  }, []);

  if (props.step !== 1) {
    return null;
  }

  return (
    <>
      <Title level={1}>Willkommen</Title>
      <Form.Item label='Firmen Name' name='companyName'>
        <Input></Input>
      </Form.Item>
      <Form.Item label='Postleitzahl' name='plz'>
        <Input required='true' placeholder='PLZ'></Input>
      </Form.Item>
      <Form.Item label='Ort' name='place'>
        <Input required='true' placeholder='PLZ'></Input>
      </Form.Item>
      <Form.Item
        name='industry'
        label='Kategorie'
        rules={[
          {
            required: true,
            message: 'Bitte eine Kategorie auswählen!',
          },
        ]}>
        <Select>
          {Industries.map((ar) => {
            return <Select.Option value={ar.name}>{ar.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>
    </>
  );
}

function StepTwo(props) {
  if (props.step !== 2) {
    return null;
  }

  return (
    <div className='ant-ant-form-vertical'>
      <Title level={1}>Anmeldung: </Title>
      <Title level={4}>Geben Sie anbei Ihre Informationen ein</Title>
      <Form.Item label='Vorname' name='userFirstName'>
        <Input></Input>
      </Form.Item>
      <Form.Item label='Nachname' name='userLastName'>
        <Input></Input>
      </Form.Item>
      <Form.Item name='phoneNo' label='Tel. Nr.'>
        <Input />
      </Form.Item>
      <Form.Item name='website' label='Webseite'>
        <Input />
      </Form.Item>
      <Form.Item
        name='submitterPosition'
        label='Deine Funktion'
        rules={[
          {
            required: true,
            message: 'Bitte Deine Funktion auswählen',
          },
        ]}>
        <Select>
          {functions.map((func) => {
            return <Select.Option value={func}>{func}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name='email'
        label='Deine E-mail'
        rules={[
          /* {
              type: 'email',
              message: 'Die E-Mail ist nicht valide',
            }, */
          {
            required: true,
            message: 'Bitte Deine E-Mail eintragen!',
          },
        ]}>
        <Input />
      </Form.Item>
    </div>
  );
}

function StepThree(props) {
  const [value, setvalue] = useState(1);
  if (props.step !== 3) {
    return null;
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const onChange = (e) => {
    setvalue(e.target.value);
  };

  return (
    <>
      <Title level={1}>Kostenvorstellung</Title>
      <Title level={4}>
        Hier können sie bestimmen wieviel Sie Für die Unterstützung spenden
        möchten
      </Title>
      <Form.Item name='costs' label='Max. Betrag'>
        <Input />
      </Form.Item>
      <p>
        Für Solidares heisst es an erster Stelle, erste Unterstützung zu
        leisten. Gestartet wird mit einem Telefongespräch von ca. 1/2 Stunde
        (wenns länger geht, gehts länger), in welchem der beabsichtigte Inhalt
        festgelegt wird.
        <br /> <br />
        Es ist ein freiwilliger Beitrag Ihrerseits an den Verein und Sie
        bestimmen wieviel Sie bereit sind zu geben. Das Geld geht in die
        Vereinskasse und wird dann für die Lancierung von weiteren solidarischen
        Initiativen verwendet.
      </p>
    </>
  );
}

function StepFour(props) {
  if (props.step !== 4) {
    return null;
  }

  return (
    <>
      <Title level={1}>Ihr Anliegen</Title>
      <Title level={4}>
        Geben Sie uns Ihr Anliegen einfach per Anmeldeformular bekannt. Wir
        werden uns umgehend bei Ihnen melden und freuen uns Ihnen bei der
        Verfassung eines individuell formulierten Schreibens zu unterstützen.
      </Title>
      <Form.Item wrapperCol={{ span: 24 }} name='request'>
        <TextArea rows={25}></TextArea>
      </Form.Item>
    </>
  );
}

function StepFive(props) {
  if (props.step !== 5) {
    return null;
  }

  console.log(props.currentData);

  const fitem = (name, value) => {
    return <Descriptions.Item label={name}>{value}</Descriptions.Item>;
  };

  return (
    <>
      <Title level={1}>Zusammenfassung</Title>
      <Descriptions
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        bordered>
        {fitem('Name:', props.currentData.companyName)}
        {fitem('PLZ:', props.currentData.plz)}
        {fitem('Kategorie:', props.currentData.industry)}
        {fitem('Tel. Nr.:', props.currentData.phoneNo)}
        {fitem('Website:', props.currentData.website)}
        {fitem('Funktion:', props.currentData.submitterPosition)}
        {fitem('EMail:', props.currentData.email)}
        {fitem('Kostenvorstellung:', props.currentData.costs)}
        <Descriptions.Item label='Anliegen:' span={2}>
          {props.currentData.request}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}
export { StepOne, StepTwo, StepThree, StepFour, StepFive };
