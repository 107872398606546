import React from 'react';
import {Button} from 'antd';
import {FacebookProvider, Share} from 'react-facebook';

export class ShareOptions extends React.Component {
    shareToFB() {
    }

    render() {
        return (
            <div>
                <FacebookProvider appId='624586478384186'>
                    <Share href='https://solidares.ch'>
                        {({handleClick, loading}) => (
                            <Button
                                onClick={handleClick}
                                shape='round'
                                className='secondary-button'>
                                Facebook
                            </Button>
                        )}
                    </Share>
                </FacebookProvider>

                <Button
                    style={{marginLeft: 12}}
                    shape='round'
                    className='secondary-button'
                    onClick={() => {
                        const url = 'https://www.solidares.ch';
                        const text =
                            'Geschäfte brauchen uns mehr denn je – der Gutsein-Gutschein kann einen großen Unterschied machen. Bitte unterstützen Sie mit mir Ihre Lieblingsrestaurants, Lädeli und weitern Geschäfte noch heute. #Solidares #Covid19 #Gutsein #Gutschein #KMU #solidarität';
                        window.open(
                            'http://twitter.com/share?url=' +
                            encodeURIComponent(url) +
                            '&text=' +
                            encodeURIComponent(text),
                            '',
                            'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
                        );
                    }}>
                    Twitter
                </Button>
            </div>
        );
    }
}

export default ShareOptions;
