import React from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

export class PlaceAutosuggestion extends React.Component {
  maxSuggestions = 8;
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      showAddModal: false,
      suggestions: [],
    };
  }

  sanitizeInput = input => {
    return input
      .trim()
      .toLowerCase()
      .replace('é', 'e');
  };
  getSuggestions = value => {
    const inputLength = value.length;
    let results;
    if (inputLength < 2) {
      return [];
    } else {
      axios
        .get('/api/places/place_autocomplete', {
          params: {
            filter: value,
          },
        })
        .then(result => {
          this.setState({
            suggestions: result.data.Places.slice(0, this.maxSuggestions),
          });
        });
    }
  };
  getSuggestionValue = suggestion => suggestion.name || '';
  renderSuggestion = suggestion => {
    return (
      <div>
        <div>{suggestion.name}</div>
        <div className='autosuggest-address'>{suggestion.address}</div>
      </div>
    );
  };
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.props.onSearchChanged(newValue);
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  renderInputComponent = inputProps => (
    <div className='react-autosuggest__wrapper'>
      <div className='react-autosuggest__left-icon'>🍽</div>
      <input {...inputProps} />
    </div>
  );

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: '‍Finde dein Restaurant, Coffee Shop, Lädeli...',
      value,
      onChange: this.onChange,
    };
    return (
      <div className='autosuggest-outer'>
        <Autosuggest
          suggestions={suggestions}
          focusInputOnSuggestionClick={false}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          highlightFirstSuggestion={true}
          renderInputComponent={this.renderInputComponent}
          onSuggestionSelected={(event, data) => {
            this.props.onAutoSuggestPlaceSelected(data.suggestion);
          }}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}
