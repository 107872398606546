import React, { useState, useEffect } from 'react';
import { Row, Typography, Col } from 'antd';
const { Title } = Typography;

export default function Contact(props) {
  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <div className='header-row' style={{ marginTop: 100 }}>
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '4em' }}>
              Kontakt und Support
            </Title>
          </Col>
        </Row>
      </div>
      <Row style={{ backgroundColor: 'white', borderRadius: 20, padding: 25 }}>
        <b>
          Bei Fragen zu unserem Service, Anregungen, Wünschen und Ideen stehen
          wir Dir gerne zur Verfügung!
        </b>

        <br />
        <br />
        <div style={{ fontSize: 20 }}>
          Du erreichst uns wie folgt:
          <br />
          <a
            href='mailto:kontakt@solidares.ch'
            style={{ fontFamily: 'open sans' }}>
            kontakt@solidares.ch
          </a>
          <br />
          Tel: 078 836 12 04
        </div>
        <br />
        <br />
        <div style={{ fontSize: 15, width: '100%', marginTop: 30 }}>
          Verein Solidares
          <br />
          Alte Landstrasse 216
          <br />
          8802 Kilchberg/ZH
        </div>
      </Row>
    </div>
  );
}
