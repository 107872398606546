import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

export default function RegistrationSuccessful(props) {
  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <div className='header-row subpage' style={{ marginTop: 300 }}>
        <Row justify='center' align='middle'>
          <Col xs={24} md={22}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '2em', padding: '10px 0' }}>
              Vielen Dank für Deine Anmeldung. <br />
              Du hörst von uns sobald Du freigeschalten bist.
            </Title>
          </Col>
        </Row>
      </div>
    </div>
  );
}
