import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import axios from 'axios';
const { Title } = Typography;

export default function IndustryDropdown(props) {
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setIndustry] = useState();

  useEffect(() => {
    axios.get('/api/places/industries').then(result => {
      let ins = result.data.Industries;
      ins.unshift({
        key: 'all',
        name: 'Alle',
      });
      setIndustries(result.data.Industries);
      setIndustry(props.selectedIndustry);
    });
  }, [props.selectedIndustry]);

  return (
    <>
      <Title level={4} style={{ display: 'inline' }}>
        Kategorie:
      </Title>
      <select
        className='area-picker'
        value={selectedIndustry}
        onChange={event => {
          setIndustry(event.target.value);
          props.onChangedIndustry(event.target.value);
        }}>
        {industries.map(ind => {
          return (
            <option key={ind.key} value={ind.key}>
              {ind.name}
            </option>
          );
        })}
      </select>
    </>
  );
}
